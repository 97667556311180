import asyncModal from 'react-async-modal';
import {ReactSVG} from "react-svg";
import React, {FC} from "react";
import close_svg from "../../assets/icons/close.svg";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import ProfileItem from "../../pages/ProfilePage/components/ProfileItem";
import AppStore from "../../store/AppStore";
import {formatDate} from "../../modules/utils";


interface ConfirmModalProps {
  resolve(unsub?: boolean): void;
}

const PaymentManageModal: FC<ConfirmModalProps> = observer(({resolve}) => {
  const {t} = useTranslation();

  const handleClose = () => {
    resolve();
  }

  const subscription = AppStore.user?.subscription;

  return (
    <div className='modal__container subscription__manage_modal'>
      <ReactSVG src={close_svg} className='modal-close' onClick={handleClose}/>
      <h4 className='text-24 text-medium mt-2'>{t('MANAGE_SUB')}</h4>
      <ProfileItem
        className='settings__item_premium'
        label='CURRENT_PLAN'
        value={t('PREMIUM')}
      >
        {subscription?.isRecurring &&
          <div className='text-danger text-14 cursor-pointer' onClick={() => resolve(true)}>{t('UNSUB')}</div>
        }
      </ProfileItem>
      <div className="subscription__manage_about">
        <div>
          <div className='text-dark-14'>{t('SUBSCRIPTION')}</div>
          <div className='text-accent '>{t(subscription?.status?.toUpperCase() || '') || '-'}</div>
        </div>
        <div>
          <div className='text-dark-14'>{t('COST')}</div>
          <div>{subscription?.monthlyAmount} {subscription?.currency} / <span
            className='text-lowercase'>{t('MONTH')}</span></div>
        </div>
        <div>
          <div className='text-dark-14'>{t(subscription?.isRecurring ? 'NEXT_PAYMENT' : 'EXPIRED_DATE')}</div>
          <div>{formatDate(subscription?.expiresAt)}</div>
        </div>
      </div>
    </div>
  );
});

const openPaymentManageModal = (): Promise<boolean> => {
  return asyncModal(PaymentManageModal, {}, {
    showCloseIcon: false,
    center: true,
    classNames: {modalContainer: 'modal__bottom'}
    // animationDuration: 0,
  });
};

export {openPaymentManageModal};
