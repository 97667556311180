import React, {FC, useEffect, useRef, useState} from 'react';
import {EAction, EModelStyle, IMessage, IModel} from "../../../../modules/rest";
import {useNavigate, useParams} from "react-router-dom";
import ChatStore from "../../../../store/ChatStore";
import {observer} from "mobx-react";
import {API, wsUrl} from "../../../../modules/api";
import {Centrifuge, PublicationContext} from "centrifuge";
import AppStore from "../../../../store/AppStore";
import {runInAction} from "mobx";
import ChatBoard from "./ChatBoard";
import ChatControl from "./ChatControl";
import arrow_tr_active_12 from '../../../../assets/icons/arrow_tr_active_12.svg';
import {
  ChatImageConfirmModal,
  openChatLvlModal,
  openModalNoMoney,
  openPushNotificationModal,
  openSetUpModal
} from "../../../../modals";
import {Spinner} from "../../../../components/Loadable";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import ChatBoardControls from './ChatBoardControls';
import moment from "moment/moment";
import {HeaderSecondary} from "../../../../containers/Header";
import Avatar from "../../../../components/Avatar";
import {thumbAvatar} from "../../../../modules/utils";
import {ReactSVG} from "react-svg";

export type PhotoInterract = {
  event: 'show_photo_request';
  interract_id: string;
  conversation_id: number;
  message: string;
  model: IModel;
  options: { option: string, pose: string, prompt: string, stars: number }[]
};


interface Props {
  chatPageType: 'list'|'chat'|'profile';
  onProfileClick: (e: any) => void;
}

const Chat: FC<Props> = observer(({chatPageType, onProfileClick}) => {
  const {t} = useTranslation();

  const _scroll: any = useRef(null);
  const navigate = useNavigate()
  const params = useParams();
  const centrifuge = useRef<Centrifuge|null>(null);
  const [loading, setLoading] = useState(false);
  const [showImageConfirm, setShowImageConfirm] = useState<PhotoInterract>();


  const publication = (ctx: PublicationContext) => {
    const data = ctx.data;
    // console.log('WSS DATA', data);

    if (data.event === 'payment_required') {
      handlePaymentRequired(data.model);
    } else if (data.event === 'stars_updated') {
      runInAction(() => (AppStore.user!.stars = Number(data.value)));
    } else if (ChatStore.activeChat && ChatStore.activeChat?.id === data.conversation_id) {
      if (data.event === 'new_level') {
        ChatStore.activeChat.progress = 0;
        ChatStore.activeChat.currentLevel = data.level;
        toast.success(t('NEW_LVL'));
      } else if (data.event === 'show_photo_request') {
        setShowImageConfirm({...data, model: ChatStore.activeChat.model} as PhotoInterract);
      } else if (data.event === 'hide_photo_request') {
        setShowImageConfirm(undefined);
      } else if (data.event === 'conversation_action') {
        runInAction(() => {
          ChatStore.activeChat!.action = data.action as EAction
          onScroll(true);
        });
      } else if (data.event === 'new_message') {
        runInAction(() => {
          ChatStore.messages.push(data.message as IMessage)
          onScroll(true);
        });
      }
      ChatStore.getList();
      onScroll(true);
    }
  }

  useEffect(() => {
    if (!AppStore.ready || !AppStore.user?.id) return;
    centrifuge.current = new Centrifuge(wsUrl, {token: API.getToken()!});
    centrifuge!.current
      .on('connecting', function (ctx) {
        // console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
      })
      .on('connected', function (ctx) {
        // console.log(`connected over ${ctx.transport}`);
      })
      .on('disconnected', function (ctx) {
        // console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
      })
      .connect();
    const sub = centrifuge.current!.newSubscription('user#' + AppStore.user?.id);
    sub!
      .on('publication', publication)
      .on('subscribing', function (ctx) {
        // console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
      })
      .on('subscribed', function (ctx) {
        // console.log('subscribed', ctx);
      })
      .on('unsubscribed', function (ctx) {
        // console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
      })
      .subscribe();
    return () => {
      centrifuge.current?.disconnect();
    };
  }, [AppStore.ready]);

  useEffect(() => {
    fetch()
  }, [params.id, AppStore.user?.id]);


  const fetch = async () => {
    const id = params.id || ChatStore.activeChat?.id || ChatStore.chats[0].id;
    if ((!id || !AppStore.user?.id) || (!params.id && window.innerWidth < 992)) return;
    if (!AppStore.user.name || !AppStore.user.gender) {
      await openSetUpModal();
    }
    try {
      const [chat, messages] = await ChatStore.getChat(id, true) || [];
      const windowId = window.location.pathname;
      if (windowId.includes(String(id)) || (!params.id)) {
        runInAction(() => {
          ChatStore.activeChat = chat;
          ChatStore.messages = messages!;
        })
      }
    } catch (e: any) {
    }
  }

  const handlePaymentRequired = (model: IModel) => {
    setShowImageConfirm(undefined);

    const firebaseMessagingPermission = localStorage.getItem('firebaseMessagingPermission');
    const firebaseMessagingRequest = localStorage.getItem('firebaseMessagingRequest');
    const diff: any = moment().diff(firebaseMessagingRequest, 'days');

    if (!firebaseMessagingPermission && (!firebaseMessagingRequest || diff > 2)) {
      openPushNotificationModal().then(permission => {
        if (permission !== 'granted') {
          handleOpenPaymentModal(model);
        }
      });
      localStorage.setItem('firebaseMessagingRequest', moment().format('YYYY-MM-DD HH:mm'));
    } else {
      handleOpenPaymentModal(model);
    }
  }

  const handleOpenPaymentModal = (model: IModel) => {
    if (AppStore.user?.isAnonymous) {
      navigate('/auth', {state: {modelIdConversation: model?.id}});
    } else {
      openModalNoMoney("chat", model?.image?.id).then((path) => {
        if (path) navigate(path);
      });
    }
  }

  const onScroll = (smooth?: boolean) => {
    if (smooth) {
      setTimeout(() => {
        _scroll?.current?.scrollTo({top: 999999, behavior: 'smooth'});
      }, 50)
    } else {
      _scroll?.current?.scrollTo({top: 999999, behavior: 'instant'});
    }
  }

  const handleTopCheck = async () => {
    if (_scroll?.current?.scrollTop === 0 && !loading && ChatStore.activeChat?.id) {
      setLoading(true);
      try {
        const messageId = ChatStore.messages?.[0]?.id ?? 0
        const messages = await API.Conversations.getMessages(ChatStore.activeChat.id, {lastMessageId: messageId})
        const height = _scroll?.current?.scrollHeight;
        runInAction(() => {
          ChatStore.messages = [...messages, ...ChatStore.messages];
          setTimeout(() => {
            _scroll?.current?.scrollTo({top: _scroll?.current?.scrollHeight - height, behavior: 'instant'})
          }, 1)

          // document.querySelector(`#message_${messageId}`)?.scrollIntoView()
        })
      } catch (e) {
      } finally {
        setLoading(false)
      }
    }
  }

  const handleBack = () => {
    runInAction(() => {
      ChatStore.activeChat = undefined;
      ChatStore.messages = [];
      if (!AppStore.user?.id) ChatStore.chats = [];
    })
    if (chatPageType === "chat") {
      navigate('/conversation', {replace: true})
    } else {
      navigate('/')
    }
  }

  const handleOpenLvlModal = (e: any) => {
    e.stopPropagation();
    openChatLvlModal(ChatStore.activeChat!).then(path => {
      if (path) navigate(path, {state: {prevPathname: window.location.pathname}});
    });
  };

  const activeModel = ChatStore.activeChat?.model;

  useEffect(() => {
    if (activeModel) {
      onScroll();
    }
  }, [activeModel]);


  const typing = ChatStore.activeChat?.action === EAction.Typing;
  const uploadingPhoto = ChatStore.activeChat?.action === EAction.UploadPhoto;

  return (
    <>
      <HeaderSecondary
        onBack={chatPageType !== "list" ? handleBack : undefined}
        coins
        title={chatPageType === "list" ? 'CHAT' : ''}
      >
        {chatPageType === "chat" && activeModel
          ?
          <div className='d-flex align-items-center cursor-pointer' onClick={onProfileClick}>
            <Avatar size='xs' image={thumbAvatar(activeModel.image?.id, 176)}/>
            <div className='ps-2'>
              <h5 className='d-flex'>
                <span className='d-grid me-1'><span className='text-truncate'>{activeModel.name}</span></span>
                <span className='text-dark'>{activeModel.age}</span>
              </h5>
              <div className='d-flex align-items-center' style={{minHeight: 20}}>
                {(!typing && !uploadingPhoto) &&
                  <div className='text-12 text-dark d-flex align-items-center'>
                    <div className='d-grid'>
                  <span className='text-truncate'>
                  {t(`CONVERSATION_LVL_${ChatStore.activeChat?.currentLevel || 1}`)}
                    </span>
                    </div>
                    <div className='d-flex align-items-center ms-1' onClick={handleOpenLvlModal}>
                      <ReactSVG src={arrow_tr_active_12} className='react-icon' style={{marginRight: 2}}/>
                      <span className='text-accent'>{t('BOOST')}</span>
                    </div>
                  </div>
                }
                {typing &&
                  <div className={`message-typing mt-0 ms-2`}>
                    <div className="message-typing-item"/>
                    <div className="message-typing-item"/>
                    <div className="message-typing-item"/>
                  </div>
                }
                {uploadingPhoto &&
                  <div className="message-uploading-progress"/>
                }
              </div>
            </div>
          </div>
          :
          null
        }
      </HeaderSecondary>
      <div className='chat-container'>
        <div className="chat-board-container blur__bg_container">
          <ChatBoardControls onProfileClick={onProfileClick}/>
          <Spinner loading={!activeModel || ChatStore.loading} absolute style={{top: 4}}/>
          <div className="chat-board" ref={_scroll} onScroll={handleTopCheck}>
            <ChatBoard typing={typing} uploading={uploadingPhoto} loading={loading}/>
          </div>
        </div>
        <ChatControl/>
        {/*@ts-ignore*/}
        <ChatImageConfirmModal data={showImageConfirm} onClose={() => setShowImageConfirm(undefined)}/>
      </div>
    </>
  );
})

export default Chat;