import React, {FC, useEffect, useRef, useState} from 'react';
import {start} from "repl";

interface Props {
  finished?: boolean;
  timing?: number|null;
}

const defaultTiming = 15

const GenerateProgress: FC<Props> = ({finished, timing = defaultTiming}) => {
  const _timer: any = useRef(null);
  const [progress, setProgress] = useState(finished ? 100 : 0);

  useEffect(() => {
    if (finished) {
      setProgress(100);
      stop()
    } else {
      setProgress(0)
      start();
    }
  }, [finished]);

  const start = () => {
    setProgress(prevState => {
      if (prevState === 95) return 95;
      if (prevState > 90) {
        _timer.current = setTimeout(start, 1000);
      } else {
        _timer.current = setTimeout(start, (timing || defaultTiming) * 1000 / 90);
      }
      return prevState + 1;
    })
  }

  const stop = () => {

    clearTimeout(_timer?.current);
  }

  return (
    <div className='generate__progress'>
      <div className="generate__progress_percent" style={{width: progress + '%'}}/>
    </div>
  );
}

export default GenerateProgress;