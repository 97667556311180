import React, {FC, useLayoutEffect} from 'react';
import {observer, useLocalObservable} from 'mobx-react';
import AppStore from '../../store/AppStore';
import {API} from '../../modules/api';
import {
  EFieldGroup,
  ESortOrder,
  IGetPhotosRequest, IModel,
  IPagedData,
  IPhoto
} from '../../modules/rest';
import {Link, useNavigate} from 'react-router-dom';
import GalleryNav from './components/GalleryNav';
import GalleryItem from './components/GalleryItem';
import {runInAction, toJS} from 'mobx';
import Pagination from '../../components/Pagination';
import Loadable, {Spinner} from '../../components/Loadable';
import {HeaderSecondary} from '../../containers/Header';
import EmptyList from '../../components/EmptyList';
import {confirmDialog} from '../../modals';
import trash from '../../assets/icons/trash.svg';
import generate_active from '../../assets/icons/generate_active.svg';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';
import Button from "../../components/Button";
import Skeleton from "../../components/Skeleton";

interface Props {
}

interface State {
  ready?: boolean;
  loading?: boolean;
  request: IGetPhotosRequest;
  selectedModel?: IModel|null;
  pager?: IPagedData<IPhoto>;
}

const GalleryPage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const st = useLocalObservable<State>(() => ({
    loading: false,
    models: [],
    request: {
      query: '',
      page: 1,
      limit: 12,
      order: ESortOrder.DESC
    }
  }));

  useLayoutEffect(() => {
    if (AppStore.ready) {
      if (!AppStore.user?.id) {
        navigate('/', {replace: true});
      } else {
        fetch();
      }

    }
  }, [AppStore.ready]);


  const fetch = async (scroll?: boolean) => {
    runInAction(() => {
      st.loading = true;
    });
    try {
      const res = await API.Gallery.getPhotos(st.request, [EFieldGroup.PhotoModel]);
      runInAction(() => {
        st.pager = res;
      });
      if (scroll) window.scrollTo({top: 0, behavior: 'auto'})
    } catch (e) {

    } finally {
      runInAction(() => {
        st.ready = true;
        st.loading = false;
      });
    }
  };

  const handleRemove = (id: number) => async (e: any) => {
    e.stopPropagation();
    const confirmed = await confirmDialog(t('Confirm_message_delete_picture'), {
      title: t('DELETE_PICTURE')!,
      icon: trash,
      confirmText: 'DELETE'
    });
    if (confirmed) {
      try {
        await API.Gallery.deletePhoto(id);
        fetch();
      } catch (e: any) {
        toast.error(e);
      }
    }
  };

  const handleChangeModel = (model: IModel|null) => {
    if(st.request.model === (model?.id || null)) return;
    runInAction(() => {
      st.request = {...st.request, model: model?.id || null};
      st.selectedModel = model;
      fetch(true);
    })
  }

  return (
    <>
      <HeaderSecondary
        onBack={() => navigate(-1)}
        title='GALLERY'
        className='gallery__page_header'
        RightComponent={
          <Button
            btnIcon
            onClick={() => navigate('/request-photo', {state: {model: toJS(st.selectedModel) || null}})}
            size='sm'
            icon={generate_active}
            btnType='secondary'
          />
        }
      />
      <main>
        <div className='gallery__page'>
          <GalleryNav ready={st.ready} activeModel={st.selectedModel} onChange={handleChangeModel}/>
          {AppStore.ready
            ?
            <>
              <div className='gallery__container'>
                <Spinner loading={st.loading && st.ready} absolute style={{top: -10}} className='align-self-center'/>
                <div className='gallery__title page-title'>
                  <h2>
                    <span className='text-accent'>{t('GALLERY')}</span>
                    {st.selectedModel ? <span> {st.selectedModel.firstName} {st.selectedModel.age}</span> : null}
                  </h2>
                  <Link to={'/request-photo'}>
                    <ReactSVG src={generate_active} className='react-icon me-2'/>
                    {t('GENERATE_NEW_IMAGE')}
                  </Link>
                </div>
                {st.ready
                  ?
                  <Loadable showSpinner={false} loading={st.loading && st.ready}>
                    <div className='row g-3 g-xl-4 gallery__list'>
                      {st.pager?.data?.length
                        ?
                        st.pager.data.map(item => (
                          <GalleryItem
                            key={item.id}
                            item={item}
                            onRemove={handleRemove(item.id)}
                            onChangeModel={handleChangeModel}
                          />
                        ))
                        :
                        <EmptyList/>
                      }
                    </div>
                  </Loadable>
                  :
                  <div className='row g-3 g-xl-4 gallery__list'>
                    {Array(8).fill({}).map((_, i) => (
                      <div className='col-6 col-md-4 col-lg-3' key={i}>
                        <Skeleton/>
                      </div>
                    ))}
                  </div>
                }
              </div>
              <Pagination
                onPageChange={(page) =>
                  runInAction(() => {
                    st.request.page = page;
                    fetch(true);
                  })
                }
                pager={st.pager}
              />

            </>
            :
            null
          }
        </div>
      </main>
    </>
  );
});

export default GalleryPage;