import React, {Suspense, useEffect, useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import {observer} from 'mobx-react';
import useUtm from './hooks/useUtm';
import useToTop from './hooks/useToTop';
import {initFirebase} from './modules/firebase';
import AppStore from './store/AppStore';
import HomeStore from './store/HomeStore';
import PreloadImages from './components/PreloadImages';
import Footer from './containers/Footer';
import {preloadSrcEthnicity, preloadSrcStyle} from './pages/CreateAIPage/assets/modules/utils';

import HomePage from './pages/HomePage';
import CreateAIPage from './pages/CreateAIPage';
import ChatPage from './pages/ChatPage';
import MyAIPage from './pages/MyAIPage';
import GalleryPage from './pages/GalleryPage';
import PaymentStatusPage from './pages/PaymentStatusPage';
import GenerateImagePage from './pages/GenerateImagePage';
import BillingAddressPage from './pages/BillingAddressPage';
import NotFoundPage from './pages/NotFoundPage';
import GeneratePage from './pages/GeneratePage';
import PaymentPage from './pages/PaymentPage';
import Header from './containers/Header';
import RefPage from './pages/RefPage';
import AuthPage from './pages/AuthPage';
import ModelProfilePage from './pages/ModelProfilePage';
import LegalInformationPage from './pages/LegalInformationPage';
import ProfilePage from './pages/ProfilePage';
import ChatCreateConversation from "./pages/ChatPage/components/ChatCreateConversation";
import {openWarning18Modal} from "./modals";
import Joyride from "react-joyride";
import CustomTooltip from "./containers/CustomTooltip";

const steps = [
  {
    disableBeacon: true,
    target: '#head__coin_btn',
    content: 'This is my awesome feature!',
  },
]

const App = observer(() => {
  useToTop();
  useUtm();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [runOnboard, setRunOnboard] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('confirm18+')) {
      openWarning18Modal().then(() => setRunOnboard(true));
    }
    initFirebase();
    AppStore.init();
    window.addEventListener("resize", resizeScreen);
    return () => window.removeEventListener("resize", resizeScreen);
  }, []);


  useEffect(() => {
    if (AppStore.ready) HomeStore.init();
  }, [AppStore.gender, AppStore.ready]);

  const resizeScreen = () => {
    setInnerWidth(window.innerWidth);
  }

  return (
    <>
      <Joyride
        spotlightPadding={4}
        continuous={false}
        tooltipComponent={CustomTooltip}
        run={runOnboard}
        steps={steps}
      />
      {innerWidth >= 992 && <Header/>}
      <Suspense>
        <Routes>
          <Route path='/r/:id' element={<RefPage/>}/>
          <Route path='/' element={<HomePage/>}/>
          <Route path='/auth/*' element={<AuthPage/>}/>
          <Route path='/conversation' element={<ChatPage chatPageType='list'/>}/>
          <Route path='/conversation/start/:modelId' element={<ChatCreateConversation/>}/>
          <Route path='/conversation/:id' element={<ChatPage chatPageType='chat'/>}/>
          <Route path='/model/:conversationId' element={<ModelProfilePage/>}/>
          {/*<Route path='/conversation/:id' element={<ChatPage/>}/>*/}

          <Route path='/create-ai' element={<CreateAIPage/>}/>

          <Route path='/premium/*' element={<PaymentPage/>}/>
          <Route path='/payment-status' element={<PaymentStatusPage/>}/>

          <Route path='/request-photo' element={<GenerateImagePage/>}/>
          <Route path='/generate/*' element={<GeneratePage/>}/>

          <Route path='/gallery' element={<GalleryPage/>}/>

          <Route path='/companions' element={<MyAIPage/>}/>

          <Route path='/billing-address' element={<BillingAddressPage/>}/>

          <Route path='/profile' element={<ProfilePage/>}/>
          <Route path='/legal-information/*' element={<LegalInformationPage/>}/>

          <Route path='*' element={<NotFoundPage/>}/>
        </Routes>
      </Suspense>
      {/*<Sider/>*/}
      <Footer/>

      <PreloadImages images={[...preloadSrcStyle, ...preloadSrcEthnicity]}/>
    </>
  );
});

export default App;