import React, {FC, useEffect, useState} from 'react';
import {ReactSVG} from "react-svg";
import arrow_svg from "../../../assets/icons/arrow-tr-20.svg";
import edit_svg from "../../../assets/icons/edit_active.svg";
import trash_svg from "../../../assets/icons/trash.svg";
import {useTranslation} from "react-i18next";
import ListPaymentCard from "../../../containers/ListPaymentCard";
import {ISavedPaymentMethod} from "../../../modules/rest";
import AppStore from "../../../store/AppStore";
import {API} from "../../../modules/api";
import {observer} from "mobx-react";
import {confirmDialog, openPaymentManageModal} from "../../../modals";
import {toast} from "react-toastify";
import Loadable from "../../../components/Loadable";

interface Props {
}

const PremiumCards: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [savedCards, setSavedCards] = useState<ISavedPaymentMethod[]>();

  useEffect(() => {
    getSavedCards()
  }, []);

  const getSavedCards = () => {
    API.SavedPaymentMethods.getSavedMethods().then(setSavedCards);
  }

  const toggleEdit = () => {
    setEdit(prevState => !prevState)
  }

  const handleUpdateDefault = (id: string) => async () => {
    try {
      setLoading(true);
      await API.SavedPaymentMethods.setDefault(id);
      getSavedCards();
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false)
    }
  }

  const handleRemove = (card: ISavedPaymentMethod) => async () => {
    const confirmed = await confirmDialog(t('DELETING_CARD_CONFIRM_TEXT', {cardNumber: card.name}), {
      title: t('DELETING_CARD')!,
      cancelText: 'NO',
    });
    if (confirmed) {
      try {
        setLoading(true);
        await API.SavedPaymentMethods.delete(card.id);
        getSavedCards()
      } catch (e: any) {
        toast.error(e)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleSubManage = async () => {
    const needUnsub = await openPaymentManageModal();
    if (needUnsub) {
      const confirmed = await confirmDialog(t('UNSUB_CONFIRM_TEXT'), {
        title: t('UNSUB')!,
        cancelText: 'NO',
      });
      if (confirmed) {
        try {
          await API.Subscriptions.unsubscribe();
          await AppStore.getUser();
          toast.success(t('UNSUB_SUCCESS'))
        } catch (e: any) {
          toast.error(e);
        }
      }
    }
  }


  return (
    <>
      {savedCards?.length ?
        <>
          <div className='d-flex justify-content-between px-12 mt-3'>
            <div className='text-dark-14'>{t('PAYMENT_METHOD')}</div>
            <div onClick={toggleEdit} className='text-accent text-14 d-flex cursor-pointer'>
              {!edit && <ReactSVG src={edit_svg} className='react-icon me-1'/>}
              {t(edit ? 'CANCEL' : 'EDIT')}
            </div>
          </div>
          <Loadable loading={loading} className='d-flex flex-column gap-1 mt-2'>
            {savedCards.map((savedCard) => (
              <div className='d-flex align-items-center w-100' key={savedCard.id}>
                <ListPaymentCard
                  className='w-100'
                  cardNumber={savedCard.name}
                  active={savedCard.isDefault}
                  onClick={handleUpdateDefault(savedCard.id)}
                />
                {edit && <ReactSVG src={trash_svg} className='react-icon mx-2 pe-1 cursor-pointer'
                                   onClick={handleRemove(savedCard)}/>}
              </div>
            ))}
          </Loadable>
        </>
        :
        null
      }
      {AppStore.user?.subscription ?
        <div onClick={handleSubManage} className='text-accent text-14 d-flex cursor-pointer py-2 mt-2 mb-1 px-12'>
          <ReactSVG src={arrow_svg} className='react-icon' style={{marginRight: 2}}/>
          {t('MANAGE_SUB')}
        </div>
        :
        null
      }
    </>
  );
})

export default PremiumCards;