import React, {FC, useLayoutEffect} from 'react';
import AppStore from "../../store/AppStore";
import {observer} from "mobx-react";
import {  Route, Routes, useNavigate} from "react-router-dom";
import PremiumPage from "./pages/PremiumPage";
import {isSub} from "../../modules/utils";
import {toast} from "react-toastify";

interface Props {
}

const PaymentPage: FC<Props> = observer(() => {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    // toast('21312312 213 12 31 2')
    // localStorage.removeItem('token')
    if (AppStore.ready) {
      if (AppStore.user?.isAnonymous) {
        navigate('/auth/register', {replace: true, state: {prevPathname: '/premium/subscription'}});
      } else if (isSub(AppStore.user)) {
        navigate('/premium/stars',{replace: true});
      }
    }
  }, [AppStore.ready]);

  if(!AppStore.ready) return null;

  return (
    <Routes>
      <Route path='/' element={<PremiumPage/>}/>
      <Route path=':paymentType' element={<PremiumPage/>}/>
      {/*<Route path=':paymentType/:paymentPlan/:paymentMethodId' element={<BillingAddressPage/>}/>*/}
      {/*<Route path='' element={<Navigate to="/premium/subscription" replace />}/>*/}
    </Routes>
  );
})

export default PaymentPage;