import {TooltipRenderProps} from 'react-joyride';
import {ReactSVG} from "react-svg";
import close from '../assets/icons/close.svg';
import AppStore from "../store/AppStore";
import React from "react";
import {useTranslation} from "react-i18next";

function CustomTooltip(props: TooltipRenderProps) {
  const {t} = useTranslation();

  const {backProps, closeProps, continuous, index, primaryProps, skipProps, step, tooltipProps} =
    props;

  return (
    <div className="tooltip__body" {...tooltipProps}>
      <div className='px-1'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='text-bold text-tint pe-2'>{t('COINS_HELPER_TITLE', {stars: AppStore.user?.stars})}</div>
          <div  {...closeProps} className="tooltip__close">
            <ReactSVG src={close}/>
          </div>
        </div>
        <div className='mt-1'>{t('COINS_HELPER_TEXT')}</div>
      </div>
      <button className="btn btn-white btn-sm w-100 mt-3" {...primaryProps}>
        {t('OK')}
      </button>
    </div>
  );
}

export default CustomTooltip