import React, {FC} from 'react';
import Button from '../../../components/Button';
import ModelCard from '../../../components/ModelCard';
import ActiveElement from '../../../components/ActiveElement';
import {EModelStyle, IModel} from '../../../modules/rest';
import {observer} from 'mobx-react';
import HomeStore from '../../../store/HomeStore';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import EmptyList from '../../../components/EmptyList';
import {toJS} from "mobx";
import Skeleton from "../../../components/Skeleton";

interface Props {
  onAnswer: (model: IModel) => void;
  className?: string;
  title?: string;
  titleClassName?: string;
}

const HomeList: FC<Props> = observer(({onAnswer, className, title, titleClassName}) => {
  const {t} = useTranslation();

  return (
    <section className={`home-models ${className || ''}`}>
      <div className='container'>
        <h2 className={`${titleClassName || ''}`}>
          {t(title || 'EXPLORE')}<span className='text-tint ms-2 ms-md-3'>{t('AI_CHARACTERS')}</span>
        </h2>
        <div className='tabs'>
          <div className={`tab${!HomeStore.style ? ' active' : ''}`} onClick={() => HomeStore.onStyleChange()}>
            {t('ALL_MODELS')}
          </div>
          {Object.values(EModelStyle).map(item => (
            <div
              key={item}
              className={`tab${item === HomeStore.style ? ' active' : ''}`}
              onClick={() => HomeStore.onStyleChange(item)}
            >
              {t(item.toUpperCase())}
            </div>
          ))}
        </div>
        <div className='w-100'>
          {HomeStore.models
            ?
            <div className='home-models-list row g-3 g-md-4'>
              {HomeStore.models?.length
                ?
                HomeStore.models.map((item, i) => (
                  <div className='col-6 col-md-4 col-lg-3' key={i}>
                    <ModelCard
                      lazy
                      model={item}
                      category={item.style}
                      onClick={() => onAnswer(item)}
                      chatBtn
                      imageSize={800}
                    />
                  </div>
                ))
                :
                <EmptyList/>
              }
            </div>
            :

            <div className='home-models-list row g-3 g-md-4'>
              {Array(8).fill({}).map((_, i) => (
                <div className='col-6 col-md-4 col-lg-3' key={i}>
                  <Skeleton/>
                </div>
              ))}
            </div>
          }
        </div>
      </div>
    </section>
  );
});

export default HomeList;