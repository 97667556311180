import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import create_ai from '../../assets/icons/create_ai.svg';
import { ReactSVG } from 'react-svg';
import StepNumber from './components/StepNumber';
import { EGender, ICreateModelRequest } from '../../modules/rest';
import { toast } from 'react-toastify';
import { API } from '../../modules/api';
import { useNavigate } from 'react-router-dom';
import AppStore from '../../store/AppStore';
import { openModalNoMoney } from '../../modals';
import { HeaderSecondary } from '../../containers/Header';
import { useTranslation } from 'react-i18next';
import { personalityData, relationshipData } from './assets/modules/utils';
import GenderSelect from '../../components/GenderSelect';
import Step1Style from './components/Step1Style';
import StepTitle from './components/StepTitle';
import Step2Ethnicity from './components/Step2Ethnicity';
import Step3Age from './components/Step3Age';
import Step4HairStyle from './components/Step4HairStyle';
import Step5HairColor from './components/Step5HairColor';
import Step6BodyType from './components/Step6BodyType';
import Step7BreastSize from './components/Step7BreastSize';
import Step8ButtSize from './components/Step8ButtSize';
import Step9Personality from './components/Step9Personality';
import Step10Interests from './components/Step10Interests';
import Step11Relationship from './components/Step11Relationship';
import Step12Summary from './components/Step12Summary';

const stepsMap: Record<EGender, ('summary' | keyof ICreateModelRequest)[]> = {
  [EGender.Female]: ['style', 'ethnicity', 'age', 'hairStyle', 'hairColor', 'bodyType', 'breastSize', 'buttSize', 'personality', 'interests', 'relationship', 'summary'],
  [EGender.Male]: ['style', 'ethnicity', 'age', 'hairStyle', 'hairColor', 'bodyType', 'personality', 'interests', 'relationship', 'summary']
};

interface Props {

}

const CreateAIPage: FC<Props> = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ready, setReady] = useState(false);
  const [step, setStep] = useState<'summary' | keyof ICreateModelRequest>(localStorage.getItem('create-ai-step') as keyof ICreateModelRequest || 'style');
  const [loading, setLoading] = useState(false);
  const startForm = localStorage.getItem('create-ai-form');
  const [form, setForm] = useState<Partial<ICreateModelRequest>>(startForm ? JSON.parse(startForm) : {});

  useEffect(() => {
    if (ready) handleReset();
    setReady(true);
  }, [AppStore.gender]);

  const handleReset = () => {
    localStorage.removeItem('create-ai-step');
    localStorage.removeItem('create-ai-form');
    setStep('style');
    setForm({});
  };

  const onStepChange = (nextStep: 'summary' | keyof ICreateModelRequest) => (data?: Partial<ICreateModelRequest>) => {
    if(nextStep === 'ethnicity') {
      API.Analytics.logEvent({event: 'model_creation_start'});
    }

    localStorage.setItem('create-ai-step', nextStep);
    if (data) {
      setForm(prevState => {
        const newForm = { ...prevState, ...(data || {}) };
        localStorage.setItem('create-ai-form', JSON.stringify(newForm));
        return newForm;
      });
    }
    setStep(nextStep);
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  const handleSubmit = async () => {
    if (loading) return;
    if (!AppStore.user?.id) {
      return navigate('/auth/register', { state: { prevPathname: '/create-ai' } });
    }
    try {
      setLoading(true);
      // @ts-ignore
      const personalityTitle: string = personalityData[form?.personality]?.title.toUpperCase();
      const res = await API.Models.create({
        ...form,
        gender: AppStore.gender,
        // @ts-ignore
        relationship: t(relationshipData[form.relationship][AppStore.gender] || ''),
        personality: `${t(personalityTitle)} (${t(`${personalityTitle}_TEXT`)})`
      } as ICreateModelRequest);
      localStorage.removeItem('create-ai-step');
      localStorage.removeItem('create-ai-form');

      navigate('/generate/model', { state: { task: res } });
    } catch (e: any) {
      const code = API.getStatusCode();
      if (code === 402) {
        if (AppStore.user?.isAnonymous) {
          navigate('/auth', { state: { prevPathname: '/create-ai' } });
        } else {
          openModalNoMoney('create-ai').then((path) => {
            if (path) navigate(path);
          });
        }
      } else {
        toast.error(e);
      }
    } finally {
      setLoading(false);
    }
  };

  const gender = AppStore.gender;
  const stepIdx = stepsMap[gender].findIndex(item => item === step);

  return (
    <>
      <HeaderSecondary gender title='CREATE_MY_AI' className='header__create_ai' coins>
        <StepNumber
          len={stepsMap[gender].length}
          step={stepIdx}
          onClick={(idx) => {
            const key = stepsMap[gender][idx] as keyof ICreateModelRequest;
            if (form[key]) setStep(stepsMap[gender][idx]);
          }}
        />
      </HeaderSecondary>
      <main>
        <div className='blur__bg_container create__ai_page'>
          <h2 className='page-title flex-column mb-1'>
            <ReactSVG src={create_ai} className='react-icon mb-1 me-0' />
            <span className='text-accent'>{t('CREATE_MY_AI')}</span>
          </h2>
          <GenderSelect onChange={handleReset} className='align-self-center' />
          <StepNumber
            len={stepsMap[gender].length}
            step={stepIdx}
            onClick={(idx) => {
              const key = stepsMap[gender][idx] as keyof ICreateModelRequest;
              if (form[key]) setStep(stepsMap[gender][idx]);
            }}
          />
          <StepTitle
            onBack={onStepChange(stepsMap[gender][stepIdx - 1])}
            onNext={onStepChange(stepsMap[gender][stepIdx + 1])}
            onSubmit={handleSubmit}
            step={step}
            showNext={Boolean(form[step as keyof ICreateModelRequest])}
          />
          <div className='w-100'>
            {step === 'style' && <Step1Style onStepChange={onStepChange('ethnicity')} data={form} />}
            {step === 'ethnicity' && <Step2Ethnicity onStepChange={onStepChange('age')} data={form} />}
            {step === 'age' && <Step3Age onStepChange={onStepChange('hairStyle')} data={form} />}
            {step === 'hairStyle' && <Step4HairStyle onStepChange={onStepChange('hairColor')} data={form} />}
            {step === 'hairColor' && <Step5HairColor onStepChange={onStepChange('bodyType')} data={form} />}
            {step === 'bodyType' &&
            <Step6BodyType onStepChange={onStepChange(gender === EGender.Female ? 'breastSize' : 'personality')}
                           data={form} />}

            {step === 'breastSize' && <Step7BreastSize onStepChange={onStepChange('buttSize')} data={form} />}
            {step === 'buttSize' && <Step8ButtSize onStepChange={onStepChange('personality')} data={form} />}
            {step === 'personality' && <Step9Personality onStepChange={onStepChange('interests')} data={form} />}
            {step === 'interests' && <Step10Interests onStepChange={onStepChange('relationship')} data={form} />}
            {step === 'relationship' && <Step11Relationship onStepChange={onStepChange('summary')} data={form} />}
            {step === 'summary' && <Step12Summary
              onStepChange={(step) => setStep(step)}
              onSubmit={handleSubmit}
              data={form}
              loading={loading}
            />}

          </div>
        </div>
      </main>
    </>
  );
});

export default CreateAIPage;