import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import card_svg from "../../assets/icons/credit-card.svg";
import card_new_svg from "../../assets/icons/credit-card-new.svg";
import {useTranslation} from "react-i18next";

interface Props {
  active?: boolean;
  newCard?: boolean;
  cardNumber?: string;
  className?: string;
  onClick: () => void;
}

const ListPaymentCard: FC<Props> = ({active, cardNumber, onClick, newCard, className}) => {
  const {t} = useTranslation();

  return (
    <div className={`list__payment_card${active ? ' active' : ''} ${className || ''}`} onClick={onClick}>
      {newCard
        ?
        <>
          <ReactSVG src={card_new_svg} className='react-icon me-2'/>
          <span className='flex-1'>{t('NEW_CARD')}</span>
        </>
        :
        <>
          <ReactSVG src={card_svg} className='react-icon me-2'/>
          <span className='flex-1'>{cardNumber}</span>
        </>
      }
      <div className="list__payment_card-radio"/>
    </div>
  );
}

export default ListPaymentCard;