import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";

interface Props {
  label?: string;
  className: string;
  value?: string|null;
  onClick?: () => void;
  children: any;
  Footer?: any;
}

const ProfileItem: FC<Props> = ({label, value, onClick, className, children, Footer}) => {
  const {t} = useTranslation();
  return (
    <div className={`settings__item ${className}`} onClick={onClick}>
      <div className='d-flex w-100 align-items-center'>
        <div className='settings__item_icon'/>
        <div className='settings__item_center'>
          <div className='text-truncate text-dark-14'>{t(label || '')}</div>
          <div className={`text-truncate text-14`}>{value}</div>
        </div>
        {children}
      </div>
      {Footer
        ?
        <div className='settings__item_footer'>
          {Footer}
        </div>
        :
        null
      }
    </div>
  );
}

export default ProfileItem;