import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {ELanguage} from "./modules/rest";
import en from './translates/en/translation.json';
import ru from './translates/ru/translation.json';
import es from './translates/es/translation.json';
import cs from './translates/cs/translation.json';
import de from './translates/de/translation.json';
import fr from './translates/fr/translation.json';
import hu from './translates/hu/translation.json';
import it from './translates/it/translation.json';
import nl from './translates/nl/translation.json';
import pl from './translates/pl/translation.json';
import pt from './translates/pt/translation.json';
import sv from './translates/sv/translation.json';

import moment from "moment";
import 'moment/locale/ru'
import 'moment/locale/es'
import 'moment/locale/en-gb'


export const availableLanguages = Object.values(ELanguage);

export const getLang = (ln) => {
  if (!ln) ln = localStorage.getItem('ln') || ( window?.navigator?.userLanguage || window.navigator.language ).substring(0, 2);
  const rusList = ['ru', 'uk', 'kk', 'be', 'az', 'lv', 'ee', 'tr', 'ka', 'hy', 'uz', 'mo', 'bg'];
  if (rusList.includes(ln || '')) ln = 'ru';
  ln = availableLanguages.includes(ln) ? ln : 'en';
  moment.locale(ln);
  return ln;
}


const resources = {
  [ELanguage.English]: {
    translation: en,
  },
  [ELanguage.Russian]: {
    translation: ru,
  },
  [ELanguage.Spanish]: {
    translation: es,
  },
  [ELanguage.Czech]: {
    translation: cs,
  },
  [ELanguage.German]: {
    translation: de,
  },
  [ELanguage.French]: {
    translation: fr,
  },
  [ELanguage.Hungarian]: {
    translation: hu,
  },
  [ELanguage.Italian]: {
    translation: it,
  },
  [ELanguage.Dutch]: {
    translation: nl,
  },
  [ELanguage.Polish]: {
    translation: pl,
  },
  [ELanguage.Portuguese]: {
    translation: pt,
  },
  [ELanguage.Swedish]: {
    translation: sv,
  },

};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    lng: getLang(),

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;