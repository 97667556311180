import React, {FC, useLayoutEffect, useState} from 'react';
import {observer} from 'mobx-react';
import PremiumBenefits from './components/PremiumBenefits';
import {ReactSVG} from 'react-svg';
import shield from '../../../../assets/icons/shield.svg';
import wallet from '../../../../assets/icons/wallet.svg';
import crypto from '../../../../assets/icons/crypto.svg';
import {EPaymentMethod, EPaymentStatus, IAddStarsRequest, ICreateSubscriptionRequest} from '../../../../modules/rest';
import {
  benefitsCredits,
  benefitsSubscription,
  creditsData,
  ICreditsDataItem,
  ISubscriptionDataItem,
  subscriptionsData,
} from './modules/utils';
import SubscriptionsPlanItem from './components/SubscriptionsPlanItem';
import CreditsPlanItem from './components/CreditsPlanItem';
import {HeaderSecondary} from '../../../../containers/Header';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Button from '../../../../components/Button';
import {API} from '../../../../modules/api';
import {toast} from 'react-toastify';
import AppStore from '../../../../store/AppStore';
import {isSub} from '../../../../modules/utils';
import {openPaymentMethodCards} from '../../../../modals';

const paymentData: any = {
  stars: {
    headerTitle: 'STARS',
    title: 'EXCLUSIVE_PACKAGE',
    subTitle: 'EXCLUSIVE_PACKAGE_TITLE',
    benefitsTitle: 'TOKEN_BENEFITS',
    benefits: benefitsCredits,
    itemsData: creditsData,
  },
  subscription: {
    headerTitle: 'PREMIUM',
    title: 'CHOOSE_PLAN',
    subTitle: 'PREMIUM_TEXT',
    benefitsTitle: 'TOKEN_BENEFITS',
    benefits: benefitsSubscription,
    itemsData: subscriptionsData,
  },
};

interface Props {}

const PremiumPage: FC<Props> = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { paymentType = 'subscription' } = useParams() || {};
  const [loading, setLoading] = useState('');
  const [paymentPlan, setPaymentPlan] = useState<ICreateSubscriptionRequest['period'] | IAddStarsRequest['stars']>(
    paymentType === 'subscription' ? '12' : '550'
  );

  const data = paymentData[paymentType!];

  useLayoutEffect(() => {
    if (!data) {
      navigate('/', { replace: true });
    } else if (!isSub(AppStore.user) && paymentType === 'stars') {
      setPaymentPlan('12');
      navigate('/premium/subscription', { replace: true });
    } else {
      if(paymentType === 'subscription') {
        API.Analytics.logEvent({event: 'view_premium_pricing'})
      } else if (paymentType === 'stars') {
        API.Analytics.logEvent({event: 'view_stars_pricing'})
      }
    }
  }, [paymentType]);

  const onSubmit = (method: EPaymentMethod) => async () => {
    if (loading) return;
    setLoading(method);
    try {
      let paymentStatus: ('new' | EPaymentStatus | null);
      const cards = await API.SavedPaymentMethods.getSavedMethods();
      if(method === 'card' && cards.length) {
        paymentStatus = await openPaymentMethodCards(cards, paymentType, paymentPlan);
        if(paymentStatus === EPaymentStatus.Success || paymentStatus === EPaymentStatus.Failed) {
          return navigate(`/payment-status?status=${paymentStatus}`);
        }
      } else {
        paymentStatus = 'new'
      }

      if(paymentStatus === 'new') {
        let res;
        if (paymentType === 'stars') {
          res = await API.Subscriptions.addStars({
            stars: paymentPlan as IAddStarsRequest['stars'],
            method,
          });
        } else {
          res = await API.Subscriptions.createSubscription({
            period: paymentPlan as ICreateSubscriptionRequest['period'],
            method,
          });
        }
        if(res.redirect) {
          window.location.href = res.redirect;
          // if (res.payment?.method === 'card') {
          //   // openPaymentIframeModal({url: res.redirect, id: res.payment.id}).then(AppStore.getUser)
          // } else {
          //   window.location.href = res.redirect;
          // }
        }
      }
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading('');
    }
  };

  if (!data) return null;
  return (
    <>
      <HeaderSecondary title={data.headerTitle} onBack={() => navigate(-1)} />
      <main>
        {/*<HeaderSecondary title={data.title} coins onClick={() => navigate(-1)} back/>*/}
        <div className="container premium__page">
          <div className="premium__page_container">
            <h2 className="text-accent text-center mb-3">{t(data.title)}</h2>
            <div className="premium__page_subtitle" dangerouslySetInnerHTML={{ __html: t(data.subTitle) || '' }} />
            <div className="row g-3">
              <div className="premium__page-bg" />
              {Object.entries(data?.itemsData)?.map(([value, item], i) =>
                paymentType === 'subscription' ? (
                  <div className="col-12" key={i}>
                    <SubscriptionsPlanItem
                      onClick={() => setPaymentPlan(value as ICreateSubscriptionRequest['period'])}
                      data={item as ISubscriptionDataItem}
                      period={value}
                      active={value === paymentPlan}
                    />
                  </div>
                ) : (
                  <div className="col-6" key={i}>
                    <CreditsPlanItem
                      onClick={() => setPaymentPlan(value as IAddStarsRequest['stars'])}
                      data={item as ICreditsDataItem}
                      credits={value}
                      active={value === paymentPlan}
                    />
                  </div>
                )
              )}
            </div>
            <Button
              loading={loading === 'card'}
              className="modal-footer w-100"
              onClick={onSubmit(EPaymentMethod.Card)}
              icon={wallet}
              iconAbsolute
              title="PAY_CARD"
            />
            <Button
              loading={loading === 'crypto'}
              className="w-100 mt-3"
              onClick={onSubmit(EPaymentMethod.Crypto)}
              icon={crypto}
              iconAbsolute
              btnType="secondary"
              title="PAY_CRYPTO"
            />
            {paymentType === 'subscription' && (
              <div className="text-dark-14 d-flex align-items-center justify-content-center mt-4">
                <ReactSVG src={shield} className="react-icon me-1" />
                {t('NO_ADULT')}
              </div>
            )}
            <PremiumBenefits title={data.benefitsTitle} data={data.benefits} />
            {/*{paymentType === 'subscription' &&*/}
            {/*  <div className='text-dark-14 mt-3 text-center'>*/}
            {/*    {subscriptionsData[paymentPlan as ICreateSubscriptionRequest['period']]?.planText &&*/}
            {/*      <div>{t(subscriptionsData[paymentPlan as ICreateSubscriptionRequest['period']]?.planText || '')}</div>}*/}
            {/*    <div>{t('CANCEL_ANYTIME')}</div>*/}
            {/*  </div>*/}
            {/*}*/}
          </div>
        </div>
      </main>
    </>
  );
});

export default PremiumPage;
