import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {EFieldGroup, EModelStyle, IModel} from "../modules/rest";
import {API} from "../modules/api";
import AppStore from "./AppStore";
import {toast} from "react-toastify";

class HomeStore {
  ready: boolean = false;
  style?: EModelStyle;
  randomModels: IModel[] = [];
  models?: IModel[];

  constructor() {
    makeAutoObservable(this, {
      ready: observable,
      style: observable,
      randomModels: observable,
      models: observable,

      init: action,
      onStyleChange: action,
    });

  }

  init = async (): Promise<any> => {
    try {
      const [randomModelsPager, modelsPager] = await Promise.all([
        API.Models.getTopModels({gender: AppStore.gender}, [EFieldGroup.ModelFull]),
        API.Models.getMainModels({gender: AppStore.gender, style: this.style}, [EFieldGroup.ModelFull])
      ])
      runInAction(() => {
        this.randomModels = randomModelsPager;
        this.models = modelsPager;
      })
    } catch (e: any) {
      toast.error(e);
    }
  };

  onStyleChange = async (style?: EModelStyle) => {
    if (style === this.style) return;
    if(style) {
      API.Analytics.logEvent({event: 'select_content', params: { content_type: 'style', item_id: style }});
    }
    try {
      runInAction(() => {
        this.style = style;
      })
      const res = await API.Models.getMainModels({
        gender: AppStore.gender,
        style: this.style
      }, [EFieldGroup.ModelFull])
      runInAction(() => {
        this.models = res
      })

    } catch (e) {

    }
  }

}

export default new HomeStore();