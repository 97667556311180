import React, {FC} from 'react';
import {formatDate} from "../../../modules/utils";
import {useTranslation} from "react-i18next";

interface Props {
  updatedAt: string;
  expiresAt: string;
}

const PremiumSubInfo: FC<Props> = ({updatedAt, expiresAt}) => {
  const {t} = useTranslation();
  return (
    <div className='settings__item_premium-info'>
      <div>
        <div className='text-dark-14'>{t('PAYMENT_DATE')}:</div>
        <div className='text-14'>{formatDate(updatedAt)}</div>
      </div>
      <div className="settings__plan_separator"/>
      <div>
        <div className='text-dark-14'>{t('EXPIRED_DATE')}:</div>
        <div className='text-14'>{formatDate(expiresAt)}</div>
      </div>
    </div>
  );
}

export default PremiumSubInfo;