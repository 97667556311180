import React, {FC, useEffect, useState} from 'react';
import {Input} from "../../../../components/FormControls";
import Button from "../../../../components/Button";
import {ReactSVG} from "react-svg";
import send from "../../../../assets/icons/send.svg";
import {API} from "../../../../modules/api";
import {toast} from "react-toastify";
import {observer} from "mobx-react";
import ChatStore from "../../../../store/ChatStore";
import AppStore from "../../../../store/AppStore";
import {useNavigate} from "react-router-dom";

interface Props {
}

const ChatControl: FC<Props> = observer(() => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');

  useEffect(() => {
    setMessage('');
  }, [ChatStore.activeChat?.id]);


  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!AppStore.user?.id) return navigate('/auth/register', {state: {modelIdConversation: ChatStore.activeChat?.model?.id}});
    if (!message) return;
    try {
      setMessage('');
      await API.Conversations.sendMessage(ChatStore.activeChat?.id!, {text: message});
    } catch (e: any) {
      toast.error(e);
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <div className={`chat__control_wrap`}>

        <div className={`chat-control`}>
          <Input
            autoFocus
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={'TYPE_MESSAGE'}
          />

          <Button btnIcon type='submit' className='ms-2' size='md' btnType={message ? 'primary' : 'secondary'}>
            <ReactSVG src={send} className='react-icon'/>
          </Button>
        </div>
      </div>
    </form>
  );
})

export default ChatControl;