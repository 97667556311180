import React, {FC} from 'react';
import Button from "../../components/Button";
import arrow_left from '../../assets/icons/chevron_left.svg';
import home_svg from '../../assets/icons/home.svg';
import {observer} from "mobx-react";
import AppStore from "../../store/AppStore";
import {useTranslation} from "react-i18next";
import GenderSelect from "../../components/GenderSelect";
import logo_img from '../../assets/images/logo.png';
import logo_small from '../../assets/images/logo_small.png';
import {Link} from "react-router-dom";
import {ReactSVG} from "react-svg";
import plus_active from "../../assets/icons/plus.svg";


interface Props {
  className?: string;
  title?: string;
  onBack?: () => void;
  home?: boolean;
  gender?: boolean;
  coins?: boolean;
  logo?: boolean;
  children?: any;
  LeftComponent?: any;
  RightComponent?: any;
}

const HeaderSecondary: FC<Props> = observer(({
                                               className,
                                               title,
                                               onBack,
                                               home,
                                               gender,
                                               logo,
                                               coins,
                                               LeftComponent,
                                               RightComponent,
                                               children,
                                             }) => {
  const {t} = useTranslation();


  return (
    <header className={`header__secondary ${className || ''}`}>
      <div className='header__secondary_left'>
        {LeftComponent || null}
        {onBack
          ?
          <Button size='sm' btnIcon icon={arrow_left} btnType='secondary' onClick={onBack}/>
          :
          null
        }
        {home
          ?
          <Button href='/' size='sm' btnIcon icon={home_svg} btnType='secondary'/>
          :
          null
        }
        {logo &&
          <Link to={'/'}>
            <img src={logo_img} alt="logo" className='header__secondary_logo'/>
            <img src={logo_small} alt="logo" className='header__secondary_logo-small'/>
          </Link>
        }
        {gender &&
          <GenderSelect icon/>
        }

      </div>
      <div className='header__secondary_center'>
        {title ? <h2>{t(title)}</h2> : null}
        {children || null}
      </div>
      <div className='header__secondary_right'>
        {coins && AppStore.user?.id ?
          <Button
            id={'head__coin_btn'}
            animate
            btnType='secondary'
            href={'/premium/stars'}
            size='md'
            className={`btn-animate_secondary btn__header_coins${AppStore.user?.isAnonymous ? ' anonymous' : ''}`}
          >
                    <span className='text-medium position-relative'>⭐<span
                      className='ms-2'>{AppStore.user?.stars}</span></span>
          </Button>
          : null}
        {RightComponent || null}
      </div>
    </header>
  );
})

export default HeaderSecondary;