import asyncModal from 'react-async-modal';
import {ReactSVG} from "react-svg";
import React, {FC, useEffect, useState} from "react";
import close_svg from "../../assets/icons/close.svg";
import card_chip from "../../assets/icons/card_chip.svg";
import check_svg from "../../assets/icons/check.svg";
import {useTranslation} from "react-i18next";
import ListPaymentCard from "../../containers/ListPaymentCard";
import Button from "../../components/Button";
import {EPaymentStatus, IAddStarsRequest, ICreateSubscriptionRequest, ISavedPaymentMethod} from "../../modules/rest";
import {API} from "../../modules/api";
import AppStore from "../../store/AppStore";


interface ConfirmModalProps {
  resolve(res?: string): void;

  paymentType: string;
  paymentPlan: ICreateSubscriptionRequest['period']|IAddStarsRequest['stars'];
  paymentMethods: ISavedPaymentMethod[];
}

const PaymentMethodCardsModal: FC<ConfirmModalProps> = ({resolve, paymentMethods, paymentType, paymentPlan}) => {
  const {t} = useTranslation();
  const defaultPaymentMethod = paymentMethods.find(item => item.isDefault);
  const [loading, setLoading] = useState(false);
  const [paymentId, setPaymentId] = useState(defaultPaymentMethod?.id || '');
  const [cardNumber, setCardNumber] = useState(defaultPaymentMethod?.name || '');
  const [processStatus, setProcessStatus] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<EPaymentStatus>();
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');

  useEffect(() => {
    let _timeout: any;
    if (!loading) {
      _timeout = setTimeout(() => setProcessStatus(false), 1000);
    }
    return () => {
      clearTimeout(_timeout);
    }
  }, [loading]);

  useEffect(() => {
    let _timeout: any;
    if (paymentStatus) {
      _timeout = setTimeout(() => resolve(paymentStatus), 2000);
    }
    return () => {
      clearTimeout(_timeout);
    }
  }, [paymentStatus]);


  const handleClick = (id: string, cardNumber?: string) => () => {
    setPaymentId(id);
    setCardNumber(cardNumber || '')
  }

  const onSubmit = async () => {
    if (paymentId === 'new') return resolve('new');
    if (loading) return;
    setLoading(true);
    try {
      let res;
      if (paymentType === 'stars') {
        res = await API.Subscriptions.addStars({
          stars: paymentPlan as IAddStarsRequest['stars'],
          savedPaymentMethod: paymentId,
        });
      } else {
        res = await API.Subscriptions.createSubscription({
          period: paymentPlan as ICreateSubscriptionRequest['period'],
          savedPaymentMethod: paymentId,
        });
      }
      setPaymentStatus(res.payment.status);
      AppStore.getUser();
    } catch (e: any) {
      setPaymentStatus(EPaymentStatus.Failed);
      setPaymentErrorMessage(e);
      // toast.error(e);
    } finally {
      setProcessStatus(true);
      setLoading(false);
    }
  }

  const handleClose = () => {
    resolve();
  }

  return (
    <div className='modal__container payment__methods_modal'>
      <ReactSVG src={close_svg} className='modal-close' onClick={handleClose}/>
      <h4 className='text-24 text-medium mt-2'>{t(loading ? 'PAYMENT' : 'PAYMENT_METHOD')}</h4>
      {loading || paymentStatus
        ?
        <div className={`payment__methods_modal-process${loading ? ' loading' : ''}`}>
          <div className="payment__methods_modal-card">
            <ReactSVG src={card_chip}/>
            <div className={'payment__methods_modal-cardNumber'}>{cardNumber}</div>
            <div className={'payment__methods_modal-cardholder'}>CARDHOLDER</div>
          </div>
          <div
            className={`payment__methods_modal-loading${processStatus ? ' finished' : ''} ${paymentStatus || EPaymentStatus.Pending}`}>
            <div className='payment__methods_modal-loading__process_item pending'>
              <div className="payment__methods_modal-loading__process">
                <div className="payment__methods_modal-loading__process_card"/>
                <div className="payment__methods_modal-loading__process_card2"/>
              </div>
              <div className='text-14 mt-3'>{t('PAYMENTS_PROCESS')}</div>
            </div>
            <div className='payment__methods_modal-loading__process_item success'>
              <ReactSVG src={check_svg} className='react-icon payment__methods_modal-status__icon success'/>
              <div className='text-14 mt-3 text-success'>{t('DONE')}</div>
            </div>
            <div className='payment__methods_modal-loading__process_item failed'>
              <ReactSVG src={close_svg} className='react-icon payment__methods_modal-status__icon failed'/>
              <div className='text-14 mt-3 text-danger'>{paymentErrorMessage || t('ERROR')}</div>
            </div>
          </div>
        </div>
        :
        <>
          <div className='d-flex flex-column gap-2 mt-3'>
            {paymentMethods.map(item => (
              <ListPaymentCard
                key={item.id}
                cardNumber={item.name}
                active={paymentId === item.id}
                onClick={handleClick(item.id, item.name)}
              />
            ))}
            <ListPaymentCard newCard onClick={handleClick('new')} active={paymentId === 'new'}/>
          </div>
          <div className="modal-footer">
            <Button
              title={'PAY'}
              onClick={onSubmit}
            />
          </div>
        </>
      }
    </div>
  );
};

const openPaymentMethodCards = (paymentMethods: ISavedPaymentMethod[], paymentType: string, paymentPlan: ICreateSubscriptionRequest['period']|IAddStarsRequest['stars']): Promise<'new' | EPaymentStatus> => {
  return asyncModal(PaymentMethodCardsModal, {paymentMethods, paymentType, paymentPlan}, {
    showCloseIcon: false,
    center: true,
    classNames: {modalContainer: 'modal__bottom'}
  });
};

export {openPaymentMethodCards};
