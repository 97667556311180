import asyncModal from 'react-async-modal';
import Button from '../../components/Button';
import React, {FC, useState} from "react";
import {EGender} from "../../modules/rest";
import {GenderIcon} from "../../components/GenderSelect";
import {toast} from "react-toastify";
import AppStore from "../../store/AppStore";
import {Input} from "../../components/FormControls";
import {useTranslation} from "react-i18next";

import user from '../../assets/icons/user.svg';
import {API} from "../../modules/api";

interface ConfirmModalProps {
  resolve(): void;
}

const SetUpModal: FC<ConfirmModalProps> = ({resolve}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(AppStore.user?.name || '');
  const [gender, setGender] = useState(EGender.Male);

  const submit = async (e: any) => {
    e.preventDefault();
    API.Analytics.logEvent({event: 'set_name'});
    if (loading) return;
    try {
      setLoading(true);
      await AppStore.updateUser({gender, name});
      resolve();
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className='modal__container modal-gender-settings'>
      <h4 className='text-center mb-3'>{t('SET_UP_MODAL_TITLE')}</h4>
      <div className='text-center text-dark-14 mb-4'>{t('SET_UP_MODAL_TEXT')}</div>
      <form onSubmit={submit}>
        <Input
          icon={user}
          className='mb-3'
          required
          placeholder='YOUR_NICKNAME'
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className='text-dark-14 mb-1'>{t('GENDER')}</div>
        <div className='d-flex gap-3'>
          {Object.values(EGender).map(item => (
            <div className={`modal__gender_item${gender === item ? ' active' : ''}`} onClick={() => setGender(item)}>
              <div className={`modal__gender_item-content`}>
                <GenderIcon gender={item} size='xl'/>
                <div className='letter-uppercase ps-2'>{t(item.toUpperCase())}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="modal-footer sm">
          <Button
            title={'CONTINUE'}
            type='submit'
          />
        </div>
      </form>
    </div>
  );
};

const openSetUpModal = () => {
  return asyncModal(SetUpModal, {}, {
    showCloseIcon: false,
    center: true,
    closeOnOverlayClick: false,
    // animationDuration: 0,
  });
};

export {openSetUpModal};
