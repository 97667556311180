import React, {FC, useEffect, useLayoutEffect, useState} from 'react';
import {observer} from "mobx-react";
import AppStore from "../../store/AppStore";
import { getLanguageName} from "../../modules/utils";
import ProfileItem from "./components/ProfileItem";
import {confirmDialog, openPasswordModal} from "../../modals";
import trash from '../../assets/icons/trash.svg';
import {toast} from "react-toastify";
import {API} from "../../modules/api";
import {useNavigate} from "react-router-dom";
import {HeaderSecondary} from "../../containers/Header";
import {useTranslation} from "react-i18next";
import {Input, Select} from "../../components/FormControls";
import user_svg from '../../assets/icons/user.svg';
import genders_svg from '../../assets/icons/genders.svg';
import lang_svg from '../../assets/icons/world.svg';
import lock_svg from '../../assets/icons/lock_danger.svg';
import edit_svg from '../../assets/icons/edit.svg';
import logout_svg from '../../assets/icons/logout_white.svg';
import crown_active from '../../assets/icons/crown_active.svg';

import {EGender, ELanguage, ESubscriptionStatus, ISavedPaymentMethod, IUpdateProfileRequest} from "../../modules/rest";
import useLanguage from "../../hooks/useLanguage";
import {ReactSVG} from "react-svg";
import Button from "../../components/Button";
import PremiumCards from "./components/PremiumCards";
import PremiumSubInfo from "./components/PremiumSubInfo";

interface Props {
}

const ProfilePage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [lang, onChangeLanguage] = useLanguage();
  const [loading, setLoading] = useState(false);
  const [changing, setChanging] = useState(false);
  const [form, setForm] = useState<IUpdateProfileRequest>({});

  const handleChange = (key: string) => (e: any) => {
    setForm(prevState => ({...prevState, [key]: e.target.value}))
    setChanging(true);
  }

  const user = AppStore.user;


  useLayoutEffect(() => {
    if (user?.isAnonymous) {
      navigate('/auth/register');
    }
  }, [AppStore.ready]);

  useEffect(() => {
    return () => {
      if (changing) {
        AppStore.updateUser(form);
      }
    }
  }, [changing]);


  useEffect(() => {
    if (AppStore.ready) {
      if (!user?.id) {
        navigate('/', {replace: true})
      } else {
        setForm({name: user?.name!, gender: user?.gender!})
      }
    }
  }, [AppStore.ready]);

  const onDeleteAcc = async () => {
    const confirmed = await confirmDialog(t('Confirm_message_delete_acc'), {
      title: t('DELETE_ACC') || '',
      icon: trash,
      cancelText: 'NO_STAY',
      confirmText: 'YES_DELETE',
    })
    if (confirmed) {
      try {
        setLoading(true);
        await API.Users.deleteAccount();
        AppStore.logout();
        navigate('/');
      } catch (e: any) {
        toast.error(e);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleLogout = () => {
    AppStore.logout();
    navigate('/');
  }

  return (
    <>
      <HeaderSecondary
        title={'PROFILE_SETTINGS'}
        onBack={() => navigate(-1)}
        RightComponent={
          <Button
            btnIcon
            size='sm'
            icon={logout_svg}
            btnType='secondary'
            onClick={handleLogout}
          />
        }
      />
      {(AppStore.ready && AppStore.user) &&
        <main className='container settings__page'>
          <div className="blur__bg_container">
            {/*<Avatar image={thumb(user?.avatar?.id, 204)} user size='xxl' className='settings__avatar'/>*/}
            <div className='d-flex position-relative flex-column flex-md-row gap-3 mb-4'>
              <Input
                icon={user_svg}
                label='NICKNAME'
                placeholder={'YOUR_NICKNAME'}
                value={form.name || ''}
                onChange={handleChange('name')}
              />
              <Select
                icon={genders_svg}
                label='GENDER'
                value={form.gender as EGender}
                onChange={handleChange('gender')}
              >
                {Object.values(EGender).map(gender => (
                  <option value={gender} key={gender}>{t(gender.toUpperCase())}</option>
                ))}
              </Select>
              <Select
                icon={lang_svg}
                label='LANGUAGE'
                value={lang}
                onChange={(e) => onChangeLanguage(e.target.value)}
              >
                {Object.values(ELanguage).map(ln => (
                  <option value={ln} key={ln}>{getLanguageName(ln)}</option>
                ))}
              </Select>
            </div>
            <ProfileItem
              className='settings__item_password'
              label='PASSWORD'
              value={'*********'}
              onClick={openPasswordModal}
              // onClick={() => openModalNoMoney('create-ai')}
            >
              <ReactSVG src={edit_svg} className='react-icon settings__item_edit'/>
            </ProfileItem>
            <ProfileItem className='settings__item_email' label='E-MAIL' value={user?.email}>
              <ReactSVG src={lock_svg} className='react-icon'/>
            </ProfileItem>
            {user?.subscription?.status === ESubscriptionStatus.Active
              ?
              <>
                <ProfileItem
                  className='settings__item_premium'
                  label='CURRENT_PLAN'
                  value={t('PREMIUM')}
                  Footer={<PremiumCards/>}
                >
                  <PremiumSubInfo updatedAt={user.subscription.updatedAt} expiresAt={user.subscription.expiresAt}/>
                </ProfileItem>
              </>
              :
              <ProfileItem
                className='settings__item_free'
                label='CURRENT_PLAN'
                value={t('FREE')}
                onClick={() => navigate('/premium/subscription')}
              >
                <ReactSVG src={crown_active} className='react-icon me-2'/>
                <span className='text-accent'>{t('SUB_OFF_75')}</span>
              </ProfileItem>
            }
            {/*<ProfileItem className='settings__item_logout'   onClick={handleLogout} >*/}
            {/*  {t('LOGOUT')}*/}
            {/*</ProfileItem>*/}
            {/*<ProfileItem className='settings__item_logout'   onClick={onDeleteAcc} >*/}
            {/*  {t('REMOVE')}*/}
            {/*</ProfileItem>*/}
          </div>
        </main>
      }
    </>
  );
})

export default ProfilePage;